import React from 'react'
import { ThemeProvider } from 'styled-components';
import { theme } from './theme'
import { GlobalStyle, SiteWrapper } from './theme/Site.style';

const TemplateWrapper = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <SiteWrapper>
        {children}
      </SiteWrapper>
    </ThemeProvider>
  )
}

export default TemplateWrapper
