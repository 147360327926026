import styled, { createGlobalStyle } from 'styled-components';
import {
  width,
  height,
  color,
  space,
  boxShadow,
  borderRadius,
} from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { normalize } from 'polished';
/* Wiltech global style */
const GlobalStyle = createGlobalStyle`
${normalize()}
  body {
    background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
      font-family: ${themeGet('fonts.body')};

    color: ${themeGet('colors.text')};
    font-size:${themeGet('fontSizes.3')}px;
    margin: 0;
  }
  *{
    font-family: ${themeGet('fonts.body')};
  }
  img{
    max-width:100%;
  }
`;

/* Wiltech wrapper style */
const SiteWrapper = styled.div`
  /* Style system supported prop */
  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}

`;

GlobalStyle.displayName = 'GlobalStyle';
SiteWrapper.displayName = 'SiteWrapper';

export { GlobalStyle, SiteWrapper };
