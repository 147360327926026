import React from 'react'
import { Box } from 'rebass/styled-components'
import { withTheme } from 'styled-components';
const Section = (props) => {
  return (
    <Box {...props.theme.section}  {...props}>
      {props.children}
    </Box>
  )
}

export default withTheme(Section);
