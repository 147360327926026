import React from 'react'
import { Box } from 'rebass/styled-components'
import { withTheme } from 'styled-components';
const Container = (props) => {
  return (
    <Box {...props.theme.container} px={props.theme.gutter} {...props} >
      {props.children}
    </Box>
  )
}

export default withTheme(Container);
