import themeInfos from "../../settings/theme.json";
const colors = {
  muted: '#f6f6f9',
  gray: '#dddddf',
  highlight: 'hsla(205, 100%, 40%, 0.125)',
  ...themeInfos.colors
};
export const theme = {
  colors: colors,
  fonts: {
    body: themeInfos.font.fontname,
    heading: themeInfos.font.fontname,
    monospace: 'Menlo, monospace'
  },
  fontSizes: [
    12,
    14,
    16,
    20,
    24,
    32,
    48,
    64,
    96
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  space: [
    0,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ],
  sizes: {
    avatar: 48
  },
  radii: {
    square: 0,
    default: 4,
    circle: 99999
  },
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)'
  },
  text: {
    heading: {
      fontFamily: 'Work Sans',
      // lineHeight: 'heading',
      // fontWeight: 'heading'
    },
    display: {
      fontFamily: 'Work Sans',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [
        5,
        6,
        7
      ]
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em'
    }
  },
  variants: {
    avatar: {
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle'
    },
    card: {
      p: 2,
      bg: 'background',
      boxShadow: 'card'
    },
    link: {
      color: 'primary'
    },
    darkBg: {
      backgroundColor: 'primary',
      color: 'background'
    },
    nav: {
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary'
      }
    }
  },
  buttons: {
    primary: {
      fontSize: 2,
      fontWeight: 'bold',
      color: 'background',
      bg: 'primary',
      borderRadius: 'default'
    },
    primaryBigLight: {
      fontSize: 6,
      fontWeight: 'normal',
      color: 'background',
      bg: 'primary',
      borderRadius: 'default',
      '&:hover':
        { border: '1px solid tomato', }
    },
    outline: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 2px'
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'background',
      bg: 'secondary'
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    }
  },
  breakpoints: Object.values(themeInfos.breakpoints),
  container: {
    width: '100%',
    maxWidth: ["100%", "100%", "750px", "970px", "1170px"],
    m: '0 auto',
    flexbox: true
  },
  section: {
    width: '100%',
    py: [30, 30, 60]
  },
  gutter: 30
};
